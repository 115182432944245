
























































// <span style="color:#1989fa">{{item.user_district_region.merger_short_name}}</span>
// <div class="flexc filt-detail" v-if="cityValueList[0].region_name!='省'">以下为<span>{{`${cityValueList[0].region_name}`}}</span><span v-if="cityValueList[1].region_name!='市'">{{`${cityValueList[1].region_name}`}}</span><span v-if="cityValueList[2].region_name!='区'">{{`${cityValueList[2].region_name}`}}</span>的代理经销商</div>
import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class RecommendList extends Vue {
  public user_id = "";
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）

  public search = ""; // 搜索框的值
  public search_value = ""; // 搜索的值


  public isShowCity = false; // 搜索的值
  public cityIndex = 0; // 搜索的值
  public cityList = []; // 搜索的值
  public cityValueList:any[] = [{region_name:'省'},{region_name:'市'},{region_name:'区'}]; // 搜索的值

  public filteList = [
    {text:'全部',value:''},
    {text:'店中店',value:'1'},
    {text:'艾灸馆',value:'2'},
  ];
  public filteIndex = 0

  filte(index){
    this.filteIndex = index
    this.init()
  }

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
      console.log(list)
    this.list.push(...list);
    console.log(this.list)
  }

  // 搜索
  onSearch() {
    this.search_value = this.search;
  }

    showCity(index){
        this.cityIndex = index
        if(index==0){//选择省
            this.isShowCity = true
            this.cityValueList[1] = {region_name:'市'}
            this.cityValueList[2] = {region_name:'区'}
            this.getCity(0,1)
        }else if(index==1){//选择市
            this.cityValueList[2] = {region_name:'区'}
            if(this.cityValueList[index-1].region_name !== '省'){
                this.isShowCity = true
                this.getCity(1,this.cityValueList[index-1].region_id||'')
            }else{
                this.$toast('请先选择省份')
            }
        }else if(index==2){//选择区
            if(this.cityValueList[index-1].region_name !== '市'){
                this.isShowCity = true
                this.getCity(1,this.cityValueList[index-1].region_id)
            }else{
                this.$toast('请先选择城市')
            }
        }
    }
    onConfirm(e){
        this.$set(this.cityValueList,this.cityIndex,e)
        this.isShowCity = false
        console.log(this.cityValueList)
    }
  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.list = [];
    this.refresh++;
  }

  getCity(index,id){//index为要获取省or市or区，id为查询id
    let _this = this
    this.$api.request({
        url: "common/region/region-all",
        data:{
            region_id:id
        },
        success(res){
            _this.cityList = res.data
        }
    })
  }
}
